<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      gradients: []
    }
  },
  watch: {
    chartData: function() {
        this._data._chart.destroy();
        this.chartData.datasets = this.chartData.datasets.map((val, indx) => {
          val.backgroundColor = this.gradients[indx]
          return val
        })
        this.renderChart(this.chartData, this.options);
    }
  },
  mounted () {
    let greenGradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    let blueGradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    let redGradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    // (154,203,65) green
    // (32,178,221) blue
    const colorOpacity = .3
    const whiteOpacity = .2
    greenGradient.addColorStop(0.3, `rgba(154, 203, 65, ${colorOpacity})`)
    greenGradient.addColorStop(1, `rgba(255, 255, 255, ${whiteOpacity})`)

    redGradient.addColorStop(0.3, `rgba(203,65,85, ${colorOpacity})`);
    redGradient.addColorStop(1, `rgba(255, 255, 255, ${whiteOpacity})`);

    blueGradient.addColorStop(0.3, `rgba(32, 178, 221, ${colorOpacity})`)
    blueGradient.addColorStop(1, `rgba(255, 255, 255, ${whiteOpacity})`);


    this.gradients = [greenGradient, blueGradient, redGradient]
    if (this._data._chart) {
      this._data._chart.destroy();
    }
    this.chartData.datasets = this.chartData.datasets.map((val, indx) => {
      val.backgroundColor = this.gradients[indx]
      return val
    })
    this.renderChart(this.chartData, this.options)
  }
}
</script>
