<template lang="html">


    <div class="main_pane home">
      <div class="pane_wrapper">
        <v-row>
          <v-col>
            <h2>Welcome to your TuxSuite account! </h2>

          </v-col>
        </v-row>
        <v-row>
          <v-col><p>This is where you can create and delete your TuxSuite tokens and manage your account. Eventually you will be able to see your builds and tests summarized, too! Thank you for using TuxSuite, and please let us know what we could do to better serve your needs at tuxsuite@linaro.org.</p></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              class="pa-6 mr-6"
              to="/tokens"
            >
              <h3>Create tokens</h3>
            </v-btn>
            <v-btn
              color="primary"
              class="pa-6"
              target="_blank"
              href="https://docs.tuxsuite.com/"
            >
              <h3>Get started</h3>
            </v-btn>

          </v-col>
        </v-row>
      </div>

    </div>
</template>

<script>
export default {
  name: "Home",
  async created() {
  }
}
</script>

<style lang="scss">
.main_pane {
  margin-left: 256px;
  padding: 10px 20px;
  .pane_wrapper {
    margin: 0 auto;
    max-width: 1200px;
  }
}
</style>
