<template lang="html">
  <div>
    <v-list-item
      :to="{name: 'PlanOverview', params: { planId: planItem.uid}}"
    >
      <v-list-item-content>
        <v-list-item-title>{{planItem.name}}</v-list-item-title>
        <v-list-item-subtitle>{{planItem.description}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mdiInformation } from '@mdi/js';
export default {
  name: "PlanListItem",
  props: ['planItem'],
  data() {
    return {
      icons: {mdiInformation}
    }
  }
}
</script>

<style lang="scss">
</style>
