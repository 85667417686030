<template lang="html">
  <div class="">
    <v-skeleton-loader
      type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
      v-show="loading"
    ></v-skeleton-loader>
    <div
      v-show="!loading"

    >
      <v-row class="d-flex justify-space-between mb-0">
        <v-col>
          <h3> {{$route.params.group}}</h3>
          <h5><span class="primary--text ">Group details</span></h5>
        </v-col>
      </v-row>
      <v-card elevation="1">
        <v-tabs dark v-model="selectedTab" ref="tabs">
          <v-tabs-slider color="primary">bos</v-tabs-slider>
          <v-tab

            v-for="tab in tabs"
            :key="tab.name"
            :to="{name: 'Group'+tab.name, params: $route.params, path: tab.route}"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <router-view class="pa-4"></router-view>
      </v-card>

    </div>

  </div>
</template>

<script>
export default {
  name: 'GroupList',

  data() {
    return {
      loading: true,
      tabs: [
        {
          name: 'Overview',
          route: ''
        },{
          name: 'Usage',
          route: 'usage'
        }
      ],
      selectedTab: null,
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('groups/getGroups'),
      this.$store.dispatch('groups/getUsageInformation')
    ])

    this.loading = false;
    this.$refs.tabs.callSlider() // bug with vuetify that makes slider visible
  },
}
</script>

<style lang="scss">
.v-data-table tbody tr:hover:not(.v-table__expanded__content) {
  cursor: pointer;
}
</style>
