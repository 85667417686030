import {$tuxApi} from '@/plugins/axios.js'

const state = () => ({
  selectedGroup: null,
  groups: [],
  groupLoading: true,
  groupDetails: {
    builds: {monthly:0,overall:0,daily:0},
    tests: {monthly:0,overall:0,daily:0},
    plans: {monthly:0,overall:0,daily:0},
  },
  groupUsageInformation: []
})

const getters = {
  selectedGroup(context, state, rootState, getters) {
    if (!context.selectedGroup) {
      return getters['groups/groups'][0]
    }
    return context.selectedGroup
  },
  groups(context, state, rootState) {
    return rootState.users.user.Groups
  },
  groupDetails(context) {
    return context.state.groupDetails
  },

}
const mutations = {
  setActiveGroup(state, group) {
    state.selectedGroup = group
  },
  setGroupLoading(state, groupLoading) {
    return state.groupLoading = groupLoading
  },
  setGroupDetails(state, details) {
    return state.groupDetails = details
  },
  setUsageInformation(state, usage) {
    return state.groupUsageInformation = usage
  }
}
const actions = {
  async getGroups(context) {
    let res = await $tuxApi(`/v1/groups/${context.state.selectedGroup}`)
    context.commit('setGroupDetails', res.data)
    context.commit('setGroupLoading', false)
  },
  async getUsageInformation(context) {
    let res = await $tuxApi(`/v1/groups/${context.state.selectedGroup}/bills`)
    context.commit('setUsageInformation', res.data.results)
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
