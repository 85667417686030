import {$tuxApi} from '@/plugins/axios.js'
import moment from 'moment';

const tzOffset = new Date().getTimezoneOffset();

const state = () => ({
  test: {},
  next: null,
  prev: null,
  count: 0,
  currentPage: 1,
  tests: []
})

const getters = {
  allTests(state) {
    return state.tests
  },
  currentPage() {
    return state.currentPage
  }
}

const mutations = {
  resetTests(state) {
    return state.tests = []
  },
  updateTests(state, tests) {
    return state.tests = state.tests.concat(tests.map(val => {
      val['provisioning_time_m'] = moment(val.provisioning_time).isValid() ? moment(val.provisioning_time).subtract(tzOffset, 'minutes').fromNow() : null
      val['finished_time_m'] = moment(val.finished_time).isValid() ? moment(val.finished_time).subtract(tzOffset, 'minutes').fromNow() : null
      return val
    }));
  },
  updateNext(state, nextToken) {
    return state.next = nextToken
  },
  updateCount(state, count) {
    return state.count = count
  }
}

const actions = {

  async getTestDetails(context, params) {
    let res = await $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/tests/${params.testId}`)
    if (res.data) {
      res.data['finished_time_m'] = moment(res.data.finished_time).isValid() ? moment(res.data.finished_time).subtract(tzOffset, 'minutes').format('MMMM Do YYYY, h:mm:ss a') : null
      res.data['running_time_m'] = moment(res.data.running_time).subtract(tzOffset, 'minutes').format('MMMM Do YYYY, h:mm:ss a')
      res.data['provisioning_time_m'] = moment(res.data.provisioning_time).subtract(tzOffset, 'minutes').format('MMMM Do YYYY, h:mm:ss a')
      let regex = /\/([a-zA-Z0-9]+)\//g;
      let matches = regex.exec(res.data.kernel);
      if (matches) {
        res.data['build_link'] = `/groups/${params.group}/projects/${params.projectName}/builds/${matches[1]}`;
      }
    }
    return res.data
  },
  async getTests(context, payload) {
    let params;
    if (payload.next) {
      params = {start: payload.next}
    }
    let res = await $tuxApi(`/v1/groups/${payload.group}/projects/${payload.projectName}/tests`, {params: params})
    context.commit('updateTests', res.data.results)
    context.commit('updateNext', res.data.next)
    context.commit('updateCount', res.data.count)
    return res.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
