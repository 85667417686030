<template>
  <div class="splash">
    <div class="callout">
      <div class="sign_in_wrap">
        <div class="sign_in">
          <img
            src="@/assets/tuxsuite.svg"
            class="logo"
            alt="Tux suite"
          />
          <v-btn elevation="0" v-if="!$auth.isAuthenticated" :color="this.$vuetify.theme.themes.light.black" @click="login">Sign in</v-btn>
        </div>
      </div>
      <div class="callout_wrap">
        <div class="">
          <h2>Build capacity is a problem of the past</h2>
          <p>TuxSuite&trade; delivers on-demand APIs and tools for building Linux kernels in parallel and at scale.</p>
        </div>
        <div class="stay_updated">
          <Signup />
        </div>

      </div>

    </div>
    <div class="vision">
      <div class="early_access">
        <v-row>
          <v-col class="develop">
            <h3>Develop.<br>Automate.<br>Scale.</h3>
          </v-col>
          <v-col><p>Use TuxMake&trade; to build, develop, and debug Linux kernels locally. Use TuxBuild&trade; to scale builds automatically and in parallel.</p></v-col>
        </v-row>
        <v-row>
          <v-col class="image-xs">
            <v-img
              max-width="250"
              eager
              contain
              src="@/assets/splash/tuxsuite_green_icons-01.png"
            ></v-img>
          </v-col>
          <v-col>
            <h4>Start with tuxmake</h4>
            <p>TuxMake automates Linux builds across different architectures, configurations, targets, and toolchains. Specify your choices, and TuxMake drives the build for you, doing the same steps in the same way every time.</p>
          </v-col>
        </v-row>
        <v-row class="make-first-xs">
          <v-col>
            <h4>TUXMAKE MANAGES BUILD ENVIRONMENTS</h4>
            <p>TuxMake provides portable build environments using standard container images so that your team can perform the exact same build across different systems.</p>
          </v-col>
          <v-col class="image-xs">
            <v-img
              max-width="250"
              src="@/assets/splash/tuxsuite_green_icons-02.png"
              eager
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="image-xs">
            <v-img
              max-width="250"
              src="@/assets/splash/tuxsuite_green_icons-03.png"
              eager
              contain
            ></v-img>
          </v-col>
          <v-col>
            <h4>Go big with tuxbuild</h4>
            <p>After automating your builds with TuxMake use TuxBuild to build all of them quickly and as often as needed. TuxBuild will handle as many builds as you can throw at it.</p>
          </v-col>
        </v-row>
        <v-row class="make-first-xs">
          <v-col>
            <h4>Realtime results</h4>
            <p>Linux kernel build status, logs, binary artifacts, and reproducer scripts are stored at a shareable URL for every TuxBuild build.</p>
          </v-col>
          <v-col class="image-xs">
            <v-img
              max-width="250"
              eager
              contain
              src="@/assets/splash/tuxsuite_green_icons-04.png"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="image-xs">
              <v-img
                max-width="250"
                src="@/assets/splash/tuxsuite_green_icons-05.png"
                eager
                contain
              ></v-img>
          </v-col>
          <v-col>
            <h4>INTEGRATE TUXBUILD IN YOUR CI PIPELINES</h4>
            <p>TuxBuild easily integrates with your existing continuous-integration and continuous-deployment (CI/CD) systems. Automatically build and test every change.</p>
          </v-col>
        </v-row>
        <v-row class="make-first-xs">
          <v-col>
            <h4>REACH YOUR COLLEAGUES</h4>
            <p>TuxBuild builds are reproducible using TuxMake. Share them using simple one-line commands that are provided with every TuxBuild build.</p>
          </v-col>
          <v-col class="image-xs">
            <v-img
              max-width="250"
              src="@/assets/splash/tuxsuite_green_icons-06.png"
              eager
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </div>
    </div>
    <div class="apis">
      <div class="show_me">
        <!-- <img class="border_img" src="@/assets/border-top.png" /> -->
        <div class="border_top">
          <div><p class="ml-6 mr-10">bash</p></div>
          <div></div>
        </div>

        <img class="console pt-4 pb-4" :src="buildSetImg" />
        <div class="border_bottom">
          <div><p style="color:transparent;" class="ml-6 mr-10">:# </p></div>
          <div></div>
        </div>
      </div>
      <div class="more_explains">
        <div>
          <v-row>
            <v-col ><h3 style="text-align:center;">TuxSuite On-Demand APIs</h3></v-col>
          </v-row>
          <v-row>
            <v-col class="col-center">
              <v-img
                max-width="180"
                src="@/assets/tuxbuild_logo_orange.svg"
                eager
                contain
              ></v-img>
            </v-col>
            <v-col>

              <h4>THE FUN LINUX KERNEL BUILDER</h4>
              <p>TuxBuild is an on demand API for building massive quantities of Linux kernels in parallel. Integrate with your command line environment or CI systems (gerrit, gitlab, github, etc.)</p>
              <div class="btn-center">
                <v-btn href="https://forms.gle/3NaW5fuNykGstsMq6" elevation="0" :color="this.$vuetify.theme.themes.light.orange">Get Early Access</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="build_flow">
      <div class="">
        <v-row></v-row>
        <v-img
          eager
          contain
          src="@/assets/yellow.svg"
          alt="This image shows a preview of what the workflow for TuxBuild looks like"
        ></v-img>
      </div>
    </div>
    <div class="open_source">
      <div class="">
        <v-row>
          <v-col><h3 style="text-align:center;">TuxSuite<br> Open<br>Source<br>Projects</h3></v-col>
          <v-col>
            <p>Linaro is an Open Source first company. The following projects are maintained and provided as a part of TuxSuite.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-center">
            <v-img
              eager
              max-width="180"
              src="@/assets/purple-penguin.svg"
              alt="This is the TuxMake logo"
              contain
            ></v-img>
          </v-col>
          <v-col>
            <h4>Local Reproducible Linux Kernel Builds</h4>
            <p>TuxMake provides portable and reproducible Linux kernel builds across multiple toolchains, architectures, kernel configurations and make targets.</p>
            <v-img
              max-width="272"
              style="margin:0 0 20px 0;"
              src="@/assets/pip-install-tuxmake.svg"
              alt="To install tuxmake, the command for linux is sudo pip install tuxmake"
              eager
              contain
            ></v-img>
            <div class="btn-center">
              <v-btn href="https://gitlab.com/Linaro/tuxmake" elevation="0" :color="this.$vuetify.theme.themes.light.purple">Get Started</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

    </div>
    <div class="make_flow">
      <div>
        <v-row></v-row>
        <v-img
          src="@/assets/purple.svg"
          eager
          contain
          alt="This is an image describing the tuxmake workflow"
        ></v-img>
      </div>
    </div>
    <div class="tux_pub">
      <div class="">
        <v-row>
          <v-col class="col-center">
            <v-img
              eager
              max-width="180"
              contain
              alt="This image is a TuxPub logo"
              src="@/assets/blue-penguin.svg"
              transition="slide-x-transition"></v-img>
          </v-col>
          <v-col>

            <h4>GLOBALLY DISTRIBUTES BUILD ARTIFACTS</h4>
            <p>TuxPub&trade; is the open source AWS S3 backed Serverless File Server, used by TuxSuite to globally distribute build artifacts.</p>
            <div class="btn-center">
              <v-btn href="https://gitlab.com/Linaro/tuxpub" elevation="0" :color="this.$vuetify.theme.themes.light.blue">Get The Source</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="supported_archs">
      <div class="supported_wrap">
        <v-row>
          <v-col class="pb-8">
            <h3>Supported Architectures and Toolchains</h3>
          </v-col>
          <v-col>
            <p>TuxBuild and TuxMake endeavor to support all upstream Linux supported toolchain and architecture combinations.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <h5>Architectures Supported</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              :key="indx"
              v-for="(arch, indx) in architectures"
              color="black"
              class="mr-2 mb-2 pl-4 pr-4"
              :text-color="$vuetify.theme.defaults.light.blue"
            >{{arch}}</v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <h5>Toolchains Supported</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              :key="indx"
              v-for="(tool, indx) in toolChains"
              color="black"
              class="mr-2 mb-2 pl-4 pr-4"
              :text-color="$vuetify.theme.defaults.light.blue"
            >{{tool}}</v-chip>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="footer">
      <img
        class="linaro_logo"
        src="@/assets/linaro-standard.png"
        alt="Linaro parent company logo"
        eager
      />
      <p>Copyright © 2021 Linaro Limited</p>
      <p style="font-weight: bold"><a style="color:black;text-decoration:none;" href="mailto:tuxsuite@linaro.org">tuxsuite@linaro.org</a></p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Signup from '@/components/Signup.vue'
export default {
  name: 'Home',
  data() {
    return {
      emailSignup: '',
      toolChains: [
        'gcc-8', 'gcc-9', 'gcc-10', 'clang-10', 'clang-11', 'clang-12', 'clang-nightly', 'clang-android'
      ],
      architectures: [
        'arc', 'arm', 'arm 64', 'i386', 'mips', 'parisc', 'powerpc', 'risc', 's390', 'sh', 'sparc', 'x86_64'
      ]
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    signUp() {
      this.$auth.loginWithRedirect({ screen_hint: 'signup' });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },

  },
  computed: {
    chipColor() {
      return this.$vuetify.theme.defaults.light.blue
    },
    buildSetImg() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return require("@/assets/tuxsuite-build.svg")
      }
      return require("@/assets/tuxsuite-build-set.svg")
    }
  },
  components: {Signup},
  created() {
    if (this.$auth.isAuthenticated) {
      this.$router.push({path: "/home"})
    }
  }
}
</script>

<style lang="scss">
$splash_width: 720px;
.v-application {
  .splash {
    .v-list-item--link:before {
      // background-color: transparent !important;
    }
    .v-btn.theme--light, .v-list-item--active.v-list-item.v-list-item--link.theme--light {
      color: $white;
      font-family: $sans;
      font-weight: bold;
      border-radius: 20px;
      text-transform: none;
      // background-color: $black !important;
      font-size: 18px;
      padding: 12px 24px;
      height: 42px;
      letter-spacing: 0px;
    }
  }

}
.splash {

  > div {

    padding: 30px 60px;
    > div {
      max-width: 1200px;

    }
  }
  h2, h3 {
    letter-spacing: -2px;
    line-height: 78px;
  }
  h3 {
    font-size: 46px;
    line-height: 50px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-decoration: underline;
  }
  h5 {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: -1px;
  }
  p {
    font-size: 18px;
  }

  .vision {
    > div {
      margin-top: 30px;
    }
  }
  .dotted_circle {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 4px dotted $black;
  }
  .row {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}


.callout {
  min-height: 80vh;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 4em;
  }
  h3 {
    font-size: 46px;
  }
  .sign_in_wrap {
    display:flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .sign_in {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .callout_wrap {
    max-width: 580px;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    .stay_updated {
      margin-top: 30px;
    }
  }
  .logo {
    height: 60px;
  }
  .formkit-powered-by-convertkit-container {
    display: none !important;
  }
  .formkit-form {
    width: fit-content;
    > div {
      padding: 0 !important;
    }
  }
  .formkit-fields {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 !important;
    > div, button {
      flex: 1;
    }
    .formkit-field {
      margin: 0 30px 0 0 !important;
      flex: none !important;
    }
    .formkit-input {

      border: 1px solid $camo !important;
      border-radius: 32px !important;
      width: 300px !important;
      text-align: center;
      &::placeholder {
        text-align: center;
        color: $placeholder !important;
      }
    }
    button {
      width: 180px !important;
      flex: none !important;
      border-radius: 32px !important;
      background-color: $green !important;
    }
  }



  h2, p {
    max-width: $splash_width;
    margin-bottom: 20px;
  }
  p {
    font-size: 22px;
  }
}
.vision {
  justify-content: center;
  display: flex;
  background-color: $green;

  .early_access {
    max-width: $splash_width;
    .develop {
    }
    .row {

      &:last-child {
        margin-bottom: 16vw;
      }
    }
  }
}


.apis {
  .show_me {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -20vw auto 4vw auto;
    max-width: 900px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 10px 30px 30px rgba(0,0,0,0.17);
    img {
      width: 100%;
      height: auto;

    }
    .console {
      padding-left:20px;
      padding-right:20px;
      background-color: rgb(40,45,53);
    }
    .border_top, .border_bottom {
      background-color: $gray;
      height: 30px;
      width: 100%;
      overflow: hidden;
      display: flex;
      div {
        background-color: $black;
      }
      > div {
        flex-grow: none;
      }
      p {
        margin-top: 2px;
        font-weight: 500;
        font-size: 1rem;
        font-family: $mono;
        color: $green;
      }
    }
    .border_bottom {
      background-color: $green;
      div {
        background-color: $black;
      }
      > div {
        flex-grow: none;
      }
    }
  }
  .more_explains {
    justify-content: center;
    display: flex;
    max-width: $splash_width;
    margin: 0 auto;

  }
}
.supported_archs {
  background-color: $blue;
  padding: 60px 60px !important;


  p, h4 {
    color: $black;
  }
  .supported_wrap {
    margin: 0 auto;
    max-width: $splash_width;
    > .row {
      margin: 0;
    }
    .v-chip {
      border-radius: 6px !important;
      font-family: $mono;
    }
  }
}
.build_flow, .open_source, .make_flow, .tux_pub {
  div {
    margin: 0 auto;
    max-width: $splash_width;
    .v-image {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }


}
.build_flow {
  background-color: $orange;
}
.make_flow {
  background-color: $purple;
}

.build_flow, .make_flow {
  div {
    max-width: 540px
  }

}
.footer {
  padding: 20px !important;
  height: 90px;
  max-width: 1280px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  .linaro_logo {
    height: 42px;
  }
  p {
    font-family: $ibm;
    font-size: 12px;
    margin-bottom:0 !important;
  }
}

.xs {
  .splash {
    > div {
      padding: 20px 40px;

    }
    h2 {
      font-size: 52px;
      line-height: 58px;
    }
    p {
      color: $black;
      font-size: 18px;
    }

  }
  .image-xs {
    margin-bottom: -30px;
    margin-left: -2px;
  }
  .btn-center {
    display: flex;
    margin-top:40px;
    justify-content: center;
  }
  .make-first-xs {
    .col:first-child {
      order: 2;
    }
    .col:last-child {
      order: 1;
    }
  }
  .vision {
    .row {
      margin-top: 10px;
      margin-bottom: 10px;
      p {
        margin-bottom: 0;
      }
      .v-image {
        margin:0 auto;
        .v-image__image {
          background-size: 120%;
        }
      }
      &:last-child {
        margin-bottom: 188px;
      }
    }
  }
  .show_me {
    margin: -188px auto 4vw auto;
  }
  .callout {
    .callout_wrap {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .formkit-fields {
      .formkit-input {
        // width: 210px !important;

      }
      .formkit-submit {
        width: 300px !important;
        margin-top: 10px !important;
        margin-left: 0px !important;
        // margin: 10px auto 0 auto !important;
      }
    }
    p {
      color: $black;
      font-size: 24px;
    }

  }
  .supported_archs {
    padding: 30px 30px !important;
  }
  .footer {
    p {
      font-size: 10px;
    }
    .linaro_logo {
      height: 26px;
    }
  }
}
</style>
