import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

// views
import Splash from '../views/Splash.vue'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import TokenRouter from '../views/tokens/Router.vue'
import TokenMain from '../views/tokens/Main.vue'

// Groups
import GroupRouter from '../views/projects/Router.vue'
import GroupDetail from '../views/projects/GroupDetail.vue'
import GroupOverview from '../views/projects/groups/GroupOverview.vue'
import GroupUsage from '../views/projects/groups/GroupUsage.vue'

// Projects
import ProjectDetail from '../views/projects/ProjectDetail.vue'
import ProjectOverview from '../views/projects/ProjectOverview.vue'


// plans
import PlanSingle from '../views/projects/plans/PlanSingle.vue'
import PlanList from '../views/projects/plans/PlanList.vue'
import PlanOverview from '../views/projects/plans/PlanOverview.vue'
import PlanBuilds from '../views/projects/plans/PlanBuilds.vue'
import PlanTests from '../views/projects/plans/PlanTests.vue'


// tests
import TestList from '../views/projects/tests/TestList.vue'
import TestSingle from '../views/projects/tests/TestSingle.vue'

// builds
import BuildList from '../views/projects/builds/BuildList.vue'
import BuildSingle from '../views/projects/builds/BuildSingle.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/tokens',
    component: TokenRouter,
    beforeEnter: authGuard,
    children: [{
      name: 'TokenMain',
      path: '',
      component: TokenMain
    }]
  },
  {
    path: '/groups/:group',
    component: GroupRouter,
    beforeEnter: authGuard,
    children: [{
      path: '',
      component: GroupDetail,
      children: [{
        name: 'GroupOverview',
        component: GroupOverview,
        path: ''
      },{
        name: 'GroupUsage',
        component: GroupUsage,
        path: 'usage'
      }]
    },{
      name: 'ProjectSingle',
      path: 'projects/:projectName/plans',
      component: PlanList,
    },{
      path: 'projects/:projectName/plans/:planId',
      component: PlanSingle,
      children: [{
        name: 'PlanOverview',
        path: '',
        component: PlanOverview
      },{
        name: 'PlanBuilds',
        path: 'builds',
        component: PlanBuilds
      },{
        name: 'PlanTests',
        path: 'tests',
        component: PlanTests
      }]
    },{
      name: 'ProjectTest',
      path: 'projects/:projectName/tests/:testId',
      component: TestSingle
    },{
      name: 'ProjectBuild',
      path: 'projects/:projectName/builds/:buildId',
      component: BuildSingle
    },{
      path: 'projects/:projectName',
      component: ProjectDetail,
      children: [{
        name: 'ProjectOverview',
        component: ProjectOverview,
        path: ''
      },{
        name: 'ProjectPlans',
        component: PlanList,
        path: 'plans'
      },{
        name: 'ProjectBuilds',
        component: BuildList,
        path: 'builds'
      },{
        name: 'ProjectTests',
        component: TestList,
        path: 'tests'
      }]
    }]
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
