<template lang="html">
  <div class="pa-3">
    <v-row>
      <v-col>
        <h3>Builds</h3>
        {{ builds.count }}
      </v-col>
      <v-col>
        <h3>Tests</h3>
        {{ tests.count }}
        <v-progress-linear
          background-color="red"
          color="primary"
          v-show="tests.count > 0"
          rounded
          height="10"
          :value="successTests * 100"
          :buffer-value="(failedTests + successTests) * 100"
          stream
        ></v-progress-linear>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "OverviewPanel",
  props: {
  },
  methods: {

  },
  computed: {
    overview() {
      return this.$store.state.projects.selectedPlanData
    },
    builds() {
      return this.$store.state.projects.selectedPlanData.builds
    },
    tests() {
      return this.$store.state.projects.selectedPlanData.tests
    },
    failedTests() {
      return this.$store.getters['projects/planMetrics'].tests.failedPercentage
    },
    successTests() {
      return this.$store.getters['projects/planMetrics'].tests.successPercentage
    }

  }
}
</script>

<style lang="scss">
</style>
