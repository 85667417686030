<template lang="html">
  <div class="ma-5">
    <v-row>
      <v-col cols="6">
        <h3>{{$route.params.planId}}</h3>
        <h5><span class="primary--text ">Plan details</span></h5>
      </v-col>
      <v-col cols="6" align-start>
        <!-- <p class="ma-0 pa-0 "><strong>Plan Description</strong>: {{$store.state.projects.selectedPlanData.description}}</p> -->
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
      v-show="$store.state.projects.planLoading"
    ></v-skeleton-loader>
    <v-row
      class="mt-3"
      v-show="!$store.state.projects.planLoading"
    >
      <v-col>
        <v-card>
          <v-tabs dark>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="tab in tabs"
              :key="tab.name"
              :to="{name: 'Plan'+tab.name, params: $route.params, path: tab.route}"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "PlanSingle",
  data() {
    return {
      tabs: [{
        name: 'Overview',
        route: ''
      },{
        name: 'Tests',
        route: 'tests'
      },{
        name: 'Builds',
        route: 'builds'
      }]
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch(
        'projects/updateSelectedPlan',
        this.$route.params
      )
    ])

  },
  methods: {},
  computed: {
    planDetails() {
      return [{
        name: 'Overview',
      }, {
        name: 'Tests',
      }, {
        name: 'Builds',
      }]
    }
  },
}
</script>

<style lang="scss">
</style>
