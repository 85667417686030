var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasTests),expression:"hasTests"}]},[_c('v-col',[_vm._v("No builds are detected for this plan.")])],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTests),expression:"!hasTests"}],attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.$store.state.itemsPerPage,"hide-default-footer":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"click:row":_vm.goToTest,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.provisioning_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFinishedTime(item.provisioning_time))+" ")]}},{key:"item.finished_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFinishedTime(item.finished_time))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.result,"size":"14","justify-center":""}})]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',[(Object.keys(item.results).length)?_vm._l((item.results),function(test,idx){return _c('v-chip',{key:(idx + "-" + test),attrs:{"color":test}},[_vm._v(_vm._s(idx))])}):_vm._l((item.tests),function(name){return _c('v-chip',{key:("" + name),attrs:{"color":"unknown"}},[_vm._v(_vm._s(name))])})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }