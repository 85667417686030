import {$tuxApi} from '@/plugins/axios.js'
import moment from 'moment';

const tzOffset = new Date().getTimezoneOffset();
const state = () => ({
  selectedProject: 'default',
  selectedPlan: '',
  projects: [{name: 'default'}],
  projectLoading: true,
  projectInit: true,
  plans: {},
  planLoading: false,
  selectedProjectData: {
    tests: {
      results: []
    },
    plans: {
      results: []
    },
    builds: {
      results: []
    }
  },
  selectedPlanData: {
    tests: {
      count: 0,
      results: []
    },
    builds: {
      count: 0,
      results: []
    }
  },
  tests: {},
  builds: {}
})
const getters = {
  planMetrics(state) {
    return {
      builds: {
        failedPercentage: 0,
        successPercentage: 0
      },
      tests: {
        failedPercentage: state.selectedPlanData.tests.results.filter(val => {
          return val.result === 'fail'
        }).length / state.selectedPlanData.tests.results.length,
        successPercentage: state.selectedPlanData.tests.results.filter(val => {
          return val.result === 'pass'
        }).length / state.selectedPlanData.tests.results.length
      }

    }
  },
  selectedPlanData(state) {
    return state.selectedPlanData
  },
  selectedProjectPlans(state) {
    return state.selectedProjectData.plans.results
  },
  selectedProjectTests: (state) => (sortBy, sortAscending = true) => {
    if (!sortBy) {
      return state.selectedProjectData.tests.results
    }
    const items = [...state.selectedProjectData.tests.results].sort((a,b) => {
			if(a[sortBy] > b[sortBy]){return 1}
			if(a[sortBy] < b[sortBy]){return -1}
			return 0
		})
    if(!sortAscending){
			items.reverse()
		}
		return items
  },
  getRepoLink: () => (item) => {
    if (!('git_repo' in item)) {
      return ''
    }
    if (item.git_repo.indexOf("git.kernel.org") !== -1) {
      let str = `${item.git_repo}/commit/?`
      if (item.git_ref && item.git_ref !== 'master') {
        str += `h=${item.git_ref}&`
      }
      str += `id=${item.git_sha}`
      return str
    } else if(item.git_repo.indexOf("github.com") !== -1) {
      return `${item.git_repo.split(".git")[0]}/commit/${item.git_sha}`

    } else if(item.git_repo.indexOf("git.linaro.org") !== -1) {
      let str = `${item.git_repo}/commit/?`
      if (item.git_ref) {
        str += `h=${item.git_ref}&`
      }
      str += `id=${item.git_sha}`
      return str

    } else if(item.git_repo.indexOf("gitlab.com" !== -1)) {
      return `${item.git_repo.split(".git")[0]}/-/commit/${item.git_sha}`

    } else {
      return item.git_repo
    }
  }
}
const mutations = {
  setProjects(state, projects) {
    return state.projects = projects;
  },
  setProjectLoading(state, projectLoading) {
    return state.projectLoading = projectLoading
  },
  setSelectedProject(state, projectName) {
    return state.selectedProject = projectName
  },
  setPlans(state, plans) {
    return state.plans = plans
  },
  setPlanLoading(state, planLoading) {
    return state.planLoading = planLoading
  },
  setSelectedPlanData(state, planData) {
    return state.selectedPlanData = planData
  },
  setTests(state, tests) {
    return state.tests = tests
  },
  setBuilds(state, builds) {
    return state.builds = builds
  },
  setSelectedProjectPlansData(state, plans) {
    return state.selectedProjectData.plans = plans
  },
  setSelectedProjectTestData(state, tests) {

    return state.selectedProjectData.tests.results = tests.results.map(val => {
      val['finished_time_m'] = moment(val.finished_time).isValid() ? moment(val.finished_time).subtract(tzOffset, 'minutes').fromNow() : null
      val['running_time_m'] = moment(val.running_time).subtract(tzOffset, 'minutes').fromNow()
      val['provisioning_time_m'] = moment(val.provisioning_time).subtract(tzOffset, 'minutes').fromNow()
      return val
    })
  },
  setSelectedProjectBuildData(state, builds) {
    return state.selectedProjectData.builds = builds
  },
  setProjectInit(state, init) {
    return state.projectInit = init;
  }
}
const actions = {
  async getProjects(context) {
    let res = await $tuxApi(`/v1/groups/${context.rootGetters['groups/selectedGroup']}/projects`)
    context.commit('setProjectInit', false)
    context.commit('setProjects', res.data.results)
    context.commit('setProjectLoading', false)
  },
  async updateSelectedPlan(context, payload) {
    context.commit('setPlanLoading', true)
    let res;
    try {
       res = await $tuxApi(
        `/v1/groups/${payload.group}/projects/${payload.projectName}/plans/${payload.planId}`
      )
    }catch(err) {
      console.log(err)
    }
    context.commit('setSelectedPlanData', res.data)
    context.commit('setPlanLoading', false)
  },
  async updateSelectedProject(context, params) {
    context.commit('setSelectedProject', params.projectName)
    let res = await Promise.all([
      $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/plans`),
      $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/tests`),
      $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/builds`),
    ])
    context.commit('setSelectedProjectPlansData', res[0].data)
    context.commit('setSelectedProjectTestData', res[1].data)
    context.commit('setSelectedProjectBuildData', res[2].data)
    context.commit('setProjectLoading', false)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
