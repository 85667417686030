<template lang="html">
  <v-main>
    <v-app-bar
        app
        color="white"
        height="62"
        elevation="1"
      >
      <v-row class="sign_in_bar" justify="center">
        <v-col align-self="center">
          <img
            height="10"
            src="@/assets/penguin.svg"
            class="logo mr-3"
            style="transform: translateY(2px);"
          />
          <img
            height="10"
            src="@/assets/tuxsuite-logo.svg"
            class="logo "
          />
        </v-col>
        <v-spacer></v-spacer>

        <v-col cols="3" class="mr-4 justify-end align-center d-flex">
          <v-select
            label="Groups"
            class="mr-4 mt-6"
            :items="groups"
            outlined
            dense
            height="1"
            :value="selectedGroup"
            @input="setActiveGroup"
            v-if="betaMode"
            return-object
          ></v-select>
          <v-avatar class="mr-0">
            <v-img
              max-height="26"
              max-width="26"
              eager
              :src="$auth.user.picture"
            ></v-img>
          </v-avatar>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="logout_carrot"
                color="grey"
              >
                {{icons.mdiChevronDown}}
              </v-icon>

            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    to="/profile"
                    text
                    color="black"
                  >profile</v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    @click="logout"
                    color="black"
                  >logout</v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <NavLeft />
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>
</template>

<script>
import NavLeft from './NavLeft.vue'
import { mdiChevronDown } from '@mdi/js';
export default {
  name: 'Frame',
  data() {
    return {
      icons: {mdiChevronDown},
    }
  },
  async created() {
    // await this.$store.dispatch('projects/getProjects');
  },
  computed: {
    groups() {
      return this.$store.getters['groups/groups']
    },
    selectedGroup() {
      return this.$store.getters['groups/selectedGroup']
    },
    betaMode() {
      return this.$store.state.users.user.BetaModes.UIEnabled
    }
  },
  components: {NavLeft},
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    setActiveGroup(group) {
      this.$store.commit('projects/setProjectInit', true)
      this.$store.commit('groups/setActiveGroup', group)
      this.$router.push({path: `/groups/${group}`})
      this.$store.commit('updateComponentKey', this.$store.state.componentKey + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 24px;
}
.logout_carrot {
  * &:after {
    display:none;
  }
}
</style>
