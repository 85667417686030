var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"text-center",attrs:{"elevation":"0"}},[_c('h3',[_vm._v("Total Plans")]),_c('v-card',{staticClass:" pa-4",attrs:{"elevation":"0","color":"grey"}},[_c('h4',{staticClass:" white--text"},[_vm._v(_vm._s(_vm.totalPlans))])])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"text-center",attrs:{"elevation":"0"}},[_c('h3',[_vm._v("Total Tests")]),_c('v-card',{staticClass:" pa-4",attrs:{"elevation":"0","color":"blue"}},[_c('h4',{staticClass:" white--text"},[_vm._v(_vm._s(_vm.totalTests))])])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:" text-center",attrs:{"elevation":"0"}},[_c('h3',[_vm._v("Total Builds")]),_c('v-card',{staticClass:" pa-4",attrs:{"elevation":"0","color":"primary"}},[_c('h4',{staticClass:" white--text"},[_vm._v(_vm._s(_vm.totalBuilds))])])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"ml-4 mt-7",attrs:{"items":_vm.filterBy,"outlined":"","dense":"","color":"primary"},model:{value:(_vm.filterSelect),callback:function ($$v) {_vm.filterSelect=$$v},expression:"filterSelect"}})],1),_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects},on:{"click:row":_vm.openProject},scopedSlots:_vm._u([{key:"header.builds",fn:function(){return [_vm._v(" Builds ")]},proxy:true},{key:"header.tests",fn:function(){return [_vm._v(" Tests ")]},proxy:true},{key:"row",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.builds",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAmount(item.builds, _vm.filterSelect))+" ")]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAmount(item.tests, _vm.filterSelect))+" ")]}},{key:"item.plans",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAmount(item.plans, _vm.filterSelect))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }