<template lang="html">
  <div class="ma-5">
    <v-row>
      <v-col cols="6">
        <h3>{{$route.params.testId}}</h3>
        <h5><span class="primary--text ">Test details</span></h5>
      </v-col>
      <v-col cols="6" class="justify-end d-flex">
        <v-icon
          class="mr-2"
          :color="test.result === 'unknown' ? test.state : test.result"
        >{{icons.mdiCircle}} </v-icon><h4 class="mt-2 overline">{{test.result === 'unknown' ? test.state : test.result}}</h4>
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
      v-show="!testLoaded"
    ></v-skeleton-loader>
    <div
      v-show="testLoaded"
      class="mt-3"
    >
      <v-card
        v-show="testLoaded"
      >
        <v-tabs
          v-model="tab"
          dark
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(item, tabIdx) in tabItems"
            :key="tabIdx"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item
            v-for="(item,idx) in tabItems"
            :key="idx"
          >
            <v-card
              elevation="0"
            >
              <div class="pa-4" v-if="item === 'overview'">
                <v-row>
                  <v-col>
                    <h4 class="primary--text">Device</h4>
                    {{test.device}}
                  </v-col>
                  <v-col>
                    <h4 class="primary--text">State</h4>
                    {{test.state}}
                  </v-col>
                  <v-col>
                    <h4 class="primary--text mb-1">Individual test results</h4>
                    <v-row>
                      <v-col>
                        <v-chip
                          v-for="(test, result) in test.results"
                          :key="result"
                          class="mr-2"
                          v-show="result"
                          :color="test">{{result}}: {{test}}</v-chip>
                      </v-col>
                      <v-col v-show="!test.result">Unknown</v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <h4 class="primary--text">Modules</h4>
                    {{test.modules || "None"}}
                  </v-col>
                  <v-col v-if="test.build_link">
                    <h4 class="primary--text">Build</h4>
                    <v-btn
                       text
                       :to="test.build_link"
                        color="primary"
                        small
                      >[build]</v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-6" justify='space-between'>
                  <v-col cols="7" >
                    <h4 class="primary--text">Timeline</h4>
                    <p class="caption">Total duration: {{timeline.duration}}</p>
                    <v-timeline>
                      <v-timeline-item
                        v-for="(tItem, timeIdx) in timeline.timeline"
                        :key="timeIdx"
                        dense
                        :color="tItem.color || test.result"
                        small
                        fill-dot
                        v-show="tItem.display"
                      >
                      <template v-slot:opposite>
                        <span
                          :class="`${tItem.color || test.result}--text`"
                          v-text="tItem.title"
                        ></span>
                      </template>
                      <v-card dense>
                        <v-card-text>
                          {{tItem.date}}
                        </v-card-text>
                      </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                  <v-col cols="4" >
                    <h4 class="primary--text mb-1">External resources</h4>
                    <v-list dense class="pa-0 ml-n4">
                      <v-list-item-group>
                        <v-list-item inactive>
                          <v-list-item-icon >
                            <v-icon>{{icons.mdiRobot}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Definitions
                              <v-btn
                                text
                                :href="routePrefix + '/definition'"
                                color="primary"
                                target="_blank"
                                small
                              >[job]</v-btn>
                              <v-btn
                                text
                                :href="routePrefix + '/device'"
                                color="primary"
                                target="_blank"
                                small
                              >[device]</v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item inactive>
                          <v-list-item-icon>
                            <v-icon>{{icons.mdiClipboardTextPlay}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Logs
                              <v-btn
                                text
                                :href="routePrefix + '/logs?format=html'"
                                color="primary"
                                target="_blank"
                                small
                              >[html]</v-btn>
                              <v-btn
                                text
                                :href="routePrefix + '/logs?format=txt'"
                                color="primary"
                                target="_blank"
                                small
                              >[txt]</v-btn>
                              <v-btn
                                text
                                small
                                :href="routePrefix + '/logs?format=yaml'"
                                color="primary"
                                target="_blank"
                              >[yaml]</v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item inactive>
                          <v-list-item-icon>
                            <v-icon>{{icons.mdiClipboardTextPlay}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              LAVA logs
                              <v-btn
                                text
                                :href="routePrefix + '/logs/lava'"
                                color="primary"
                                target="_blank"
                                small
                              >[yaml]</v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item inactive>
                          <v-list-item-icon>
                            <v-icon>{{icons.mdiCodeJson}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Results
                              <v-btn
                                text
                                :href="routePrefix + '/results'"
                                color="primary"
                                target="_blank"
                                small
                              >[json]</v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item inactive>
                          <v-list-item-icon>
                            <v-icon>{{icons.mdiFolderZip}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Kernel
                              <v-btn
                                text
                                :href="test.kernel"
                                color="primary"
                                target="_blank"
                                small
                              >[bzImage]</v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>

                </v-row>
              </div>
              <div class="pa-2" v-if="item === 'definition'">
                <v-skeleton-loader
                  type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
                  v-show="!definitionLoaded"
                ></v-skeleton-loader>
                <iframe
                  style="border:0;width:100%;height:70vh"
                  :src="definitionSource"
                  @load="setDefinitionLoaded"
                ></iframe>
              </div>
              <div v-if="item === 'logs'">
                <v-skeleton-loader
                  type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
                  v-show="!logSourceLoaded"
                ></v-skeleton-loader>
                <iframe
                  style="border:0;width:100%;height:70vh"
                  :src="logSource"
                  @load="setLogSourceLoaded"
                ></iframe>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

    </div>

  </div>
</template>

<script>
import moment from 'moment'
import {
  mdiCircle, mdiClockStart, mdiRobot, mdiClipboardTextPlay,
  mdiCodeJson, mdiFolderZip
} from '@mdi/js';
export default {
  name: 'TestSingle',
  data() {
    return {
      tab: {},
      interval: null,
      test: {
        result: 'unknown',
        results: {something: 'non'}
      },
      testLoaded: false,
      tabItems: [
        'overview',
        // These are disabled until we get CORS working for the s3 endpoints for logs
        // 'definition',
        // 'logs'
      ],
      icons: {
        mdiCircle,mdiClockStart,mdiRobot,mdiClipboardTextPlay,
        mdiCodeJson, mdiFolderZip
      },
      definition: {},
      definitionLoaded: false,
      logSourceLoaded: false
    }
  },
  async created() {
    await this.loadData()
    this.testLoaded = true;
    if (!this.test.finished_time) {
      this.interval = setInterval(function () {
        this.loadData();
      }.bind(this), 2000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    setDefinitionLoaded() {
      this.definitionLoaded = true;
    },
    setLogSourceLoaded() {
      this.logSourceLoaded = true;
    },
    async loadData() {
      this.test = await this.$store.dispatch('tests/getTestDetails', this.$route.params)
      if (this.test.finished_time) {
        clearInterval(this.interval)
      }
    }
  },
  computed: {
    timeline() {
      let duration;
      if (this.test.duration) {
          duration = this.test.duration;
          duration = moment.utc(duration*1000).format('HH:mm:ss')
      }else {
        let running = moment(this.test.running_time);
        let finished = moment(this.test.finished_time);
        if (running.isValid() && finished.isValid()) {
            duration = Math.floor(moment.duration(finished.diff(running)).asSeconds());
            duration = moment.utc(duration*1000).format('HH:mm:ss');
        } else {
            duration = null;
        }
      }
      return {
        duration: duration || '00:00:00',
        timeline: [{
          title: "Provisioned",
          date: this.test.provisioning_time_m,
          color: "orange",
          display: this.test.provisioning_time || null
        },{
          title: "Running",
          date: this.test.running_time_m,
          color: "blue",
          display: this.test.running_time || null
        }, {
          title: "Finished",
          date: this.test.finished_time_m,
          color: null,
          display: this.test.finished_time || null
        }]
      }
    },
    routePrefix() {
      return `${process.env.VUE_APP_TUXAPI_ENDPOINT}/v1/groups/${this.$route.params.group}/projects/${this.$route.params.projectName}/tests/${this.$route.params.testId}`
    }
  }
}
</script>

<style lang="scss">
</style>
