<template lang="html">
  <div>
    <v-skeleton-loader
      type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
      v-show="loading"
    ></v-skeleton-loader>
    <div
      v-show="!loading"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectDetail",
  data() {
    return {
      projectVisibility: false,
      loading: true
    }
  },
  async created() {
    // set group in case its a fresh link to this page
    this.$store.commit('groups/setActiveGroup', this.$route.params.group)
    await this.$store.dispatch(
      'projects/updateSelectedProject',
      this.$route.params
    )
    this.loading = false;
  },
  computed: {
    plans() {
      return this.$store.state.projects.plans
    }
  },

}
</script>

<style lang="scss">
</style>
