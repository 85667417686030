<template lang="html">
  <div>
    <v-list-item
      :to="{name: 'ProjectBuild', params: { buildId: buildItem.uid}}"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{buildItem.build_name || buildItem.uid}}
        </v-list-item-title>
        <v-list-item-subtitle class="">
          {{buildItem.target_arch}} <span class="black--text ">|</span>
          {{buildItem.toolchain}} <span class="black--text ">|</span>
          {{buildItem.kernel_version}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon
          :color="buildItem.result"
        >
          {{icons.mdiCircle}}
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import moment from 'moment'
import { mdiCircle,mdiGit } from '@mdi/js';
export default {
  name: "BuildListItem",
  props: ['buildItem'],
  data() {
    return {
      icons: {mdiCircle,mdiGit}
    }
  },
  methods: {
    getFinishedTime(item) {
      return moment(item).fromNow()
    }
  }
}
</script>

<style lang="scss">
</style>
