import {$tuxApi} from '@/plugins/axios.js'


const state = () => ({
  plan: {},
  next: null,
  prev: null,
  count: 0,
  currentPage: 1,
  plans: []
})

const getters = {
  allPlans(state) {
    return state.plans
  },
  currentPage() {
    return state.currentPage
  }
}

const mutations = {
  resetPlans(state) {
    return state.plans = []
  },
  updatePlans(state, plans) {
    return state.plans = state.plans.concat(plans)
  },
  updateNext(state, nextToken) {
    return state.next = nextToken
  },
  updateCount(state, count) {
    return state.count = count
  }
}

const actions = {

  async getPlanDetails(context, params) {
    let res = await $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/plans/${params.planId}`)
    return res.data
  },
  async getPlans(context, payload) {
    let params;
    if (payload.next) {
      params = {start: payload.next}
    }
    let res = await $tuxApi(`/v1/groups/${payload.group}/projects/${payload.projectName}/plans`, {params: params})
    context.commit('updatePlans', res.data.results)
    context.commit('updateNext', res.data.next)
    context.commit('updateCount', res.data.count)
    return res.data
  },
  async getPlanDefinition() {
    // let res = await $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/plans/${params.planId}/definition`)
    // console.log(res)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
