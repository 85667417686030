import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users.js'
import tokens from './modules/tokens.js'
import projects from './modules/projects.js'
import groups from './modules/groups.js'
import builds from './modules/builds.js'
import tests from './modules/tests.js'
import plans from './modules/plans.js'
import {$tuxApi, $tuxAuth} from '@/plugins/axios.js'

import { getInstance } from "@/auth";

Vue.use(Vuex)
const debug = process.env.VUE_APP_GA

export default new Vuex.Store({
  state: {
    status: '',
    token: '',
    itemsPerPage: 50,
    user : {},
    environ: process.env.NODE_ENV,
    ga: process.env.NODE_ENV === "development",
    componentKey: 0
  },
  mutations: {
    setToken(state, token) {
      $tuxAuth.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      $tuxApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      state.token = token;
    },
    updateComponentKey(state, key) {
      state.componentKey = key; //force ui refresh with component key
    }
  },
  getters : {
    token(state) {
      return state.token;
    }
  },
  actions: {
    retrieveTokenFromAuthz(context) {
      return new Promise((resolve, reject) => {
        const instance = getInstance();
        instance.$watch("loading", loading => {
          if (loading === false && instance.isAuthenticated) {
            instance
              .getTokenSilently()
              .then(authToken => {
                context.commit("setToken", authToken);
                resolve(authToken);
              })
              .catch(error => {
                reject(error);
              });
          }
        });
      });
    }
  },
  modules: {users,tokens,projects,groups,builds,tests,plans},
  strict: debug
})
