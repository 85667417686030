<template lang="html">
  <div class="main_pane tokens">
    <div class="pane_wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenRouter",
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" >

</style>
