import {$tuxAuth} from '@/plugins/axios.js'

const state = () => ({
  tokens: []
})

const getters = {
  allTokens(state) {
    return state.tokens;
  }
}
const mutations = {
  setTokens(state, tokens) {
    let newTokens = tokens.sort((a,b) => {

      let compare = 0;
      if (a['CreatedAt'] < b['CreatedAt']) {
          compare = 1;
      } else if (b['CreatedAt'] < a['CreatedAt']) {
          compare = -1;
      }
      return compare;
    })
    return state.tokens = newTokens;
  },
  addToken(state, token) {
    return state.tokens.unshift(token);
  },
  deleteToken(state, ID) {
    let newTokens = state.tokens.filter(function( obj ) {
      return obj.SK !== `token#${ID}`;
    });

    return state.tokens = newTokens;
  }
}
const actions = {
  async listMyTokens(context) {
    let tokens = await $tuxAuth.get('/v1/tokens')
    context.commit("setTokens", tokens.data.Tokens);
  },
  async createToken(context, payload) {
    try {
      let newToken = await $tuxAuth.post('/v1/tokens', payload)
      if (newToken.status === 200) {
        context.commit("addToken", newToken.data)
      }
      return newToken;
    }catch(e) {
      return e;
    }
  },
  async deleteToken(context, payload) {
    let res = await $tuxAuth.delete('/v1/tokens', {data: payload})
    if (res.status === 200) {
      context.commit("deleteToken", payload.ID)
    } else {
      return res.status
    }
  }
}



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
