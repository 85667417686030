<template lang="html">
  <div class="">
    <v-row>
      <v-col cols="6">
        <h3>{{$store.getters['groups/selectedGroup']}}</h3>
        <h5><span class="primary--text ">Project Details</span></h5>
      </v-col>
      <v-col cols="6" align-start>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col v-for="section in sections" :key="section.name">
        <v-row justify="space-around">
          <v-col>
            <h4>Recent {{section.name}}</h4>
          </v-col>
          <v-col align="right">
            <v-btn
              small
              outlined
              text
              color="primary"
              :to="{name: section.route}"
            >All {{section.name.toLowerCase()}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-card elevation="1" rounded>
              <v-row v-show="preview[section.name.toLowerCase()].length === 0">
                <v-col align="center">
                  <p class="gray--text subtitle-2 font-weight-thin">No {{section.name.toLowerCase()}} detected yet.</p>
                  <img width="50" src="@/assets/penguin.svg" alt="Penguin flapping">
                </v-col>

              </v-row>
              <v-list
                v-show="section.component === 'BuildListItem'"
              >
                <BuildListItem
                  v-for="buildItem in preview[section.name.toLowerCase()]"
                  :key="buildItem.uid"
                  :buildItem="buildItem"
                />
              </v-list>
              <v-list
                v-show="section.component === 'TestListItem'"
              >
                <TestListItem
                  v-for="testItem in preview[section.name.toLowerCase()]"
                  :key="testItem.uid"
                  :testItem="testItem"
                />
              </v-list>
              <v-list
                v-show="section.component === 'PlanListItem'"
              >
                <PlanListItem
                  v-for="planItem in preview[section.name.toLowerCase()]"
                  :key="planItem.uid"
                  v-show="section.component === 'PlanListItem'"
                  :planItem="planItem"
                />
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="preview[section.name.toLowerCase()].length > 0">
          <v-col align="center">
            <v-btn
              small
              outlined
              text
              color="primary"
              @click="increaseView(section.name)"
            >...</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import BuildListItem from '@/components/BuildListItem.vue'
import TestListItem from '@/components/TestListItem.vue'
import PlanListItem from '@/components/PlanListItem.vue'
import { mdiInformation } from '@mdi/js';
export default {
  name: 'ProjectOverview',
  data() {
    return {
      icons: {mdiInformation},
      sections: [{
        name: 'Plans',
        route: 'ProjectPlans',
        component: 'PlanListItem'
      },{
        name: 'Tests',
        route: 'ProjectTests',
        component: 'TestListItem'
      },{
        name: 'Builds',
        route: 'ProjectBuilds',
        component: 'BuildListItem'
      }],
      previewCount: {
        plans: 5,
        builds: 5,
        tests: 5
      }
    }
  },
  computed: {
    preview() {
      return {
        plans: this.$store.state.projects.selectedProjectData.plans.results.slice(0, this.previewCount['plans']),
        builds: this.$store.state.projects.selectedProjectData.builds.results.slice(0, this.previewCount['builds']),
        tests: this.$store.getters['projects/selectedProjectTests']('finished_by').slice(0, this.previewCount['tests'])
      }
    }
  },
  components: {BuildListItem,PlanListItem,TestListItem},
  methods: {
    increaseView(section) {
      this.previewCount[section.toLowerCase()] = this.previewCount[section.toLowerCase()] + 5;
    }
  }
}
</script>

<style lang="scss">
.v-list {
  div:last-child hr {
    display:none;
  }
}
</style>
