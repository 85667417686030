<template>
  <v-app v-if="!$auth.loading" :key="componentKey" :class="breakpointClass">

    <Splash v-if="!$auth.isAuthenticated" />
    <transition name="fade">
      <LoadingMain v-if="$auth.isAuthenticated && !dataLoaded || $auth.loading" />
    </transition>
    <transition name="fade">
        <Frame v-if="$auth.isAuthenticated && dataLoaded" />
    </transition>
  </v-app>
</template>

<script>
import { mdiTwitter, mdiEmail } from '@mdi/js'
import Frame from './views/Frame.vue'
import Splash from './views/Splash.vue'
import LoadingMain from './views/LoadingMain.vue'
export default {
  name: 'app',
  data() {
    return {
      twitterIcon: mdiTwitter,
      emailIcon: mdiEmail,
      dataLoaded: false,
      // componentKey //force re render for global changes
    }
  },
  computed: {
    breakpointClass() {
      return this.$vuetify.breakpoint.name
    },
    componentKey() {
      return this.$store.state.componentKey
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    signUp() {
      this.$auth.loginWithRedirect();
    }
  },
  async created() {
    await this.$store.dispatch('retrieveTokenFromAuthz')
    await this.$store.dispatch('users/updateUser', this.$auth.user)
    this.dataLoaded = true;
  },
  components: {Splash,LoadingMain, Frame}
}
</script>


<style lang="scss">


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
