<template lang="html">
  <div class="ma-5">
    <v-row>
      <v-col cols="6">
        <h3>{{$route.params.buildId}}</h3>
        <h5><span class="primary--text ">Build details</span></h5>
      </v-col>
      <v-col cols="6" class="justify-end d-flex">
        <v-icon
          class="mr-2"
          :color="build.result"
        >{{icons.mdiCircle}} </v-icon><h4 class="mt-2 overline">{{build.result}}</h4>
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
      v-show="!buildLoaded"
    ></v-skeleton-loader>
    <div
      v-show="buildLoaded"
      class="mt-3"
    >
      <v-card>
        <v-tabs
          v-model="tab"
          dark
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(item, tabIdx) in tabItems"
            :key="tabIdx"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pa-4"
        >
          <v-tab-item
            v-for="(item,idx) in tabItems"
            :key="idx"
          >
          <v-row>
            <v-col>
              <h4 class="ml-5 primary--text">General</h4>
              <v-list
                dense
                tile
              >
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiRulerSquare}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Target architecture</v-list-item-title>
                    <v-list-item-subtitle class="primary--text" v-text="build.target_arch"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiHammerWrench}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Toolchain</v-list-item-title>
                    <v-list-item-subtitle class="primary--text" v-text="build.toolchain"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiAccount}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>User</v-list-item-title>
                    <v-list-item-subtitle class="primary--text" v-text="build.user"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
            <v-col>
              <h4 class="primary--text">Artifacts</h4>
              <v-list
                dense
                tile
              >

                <v-list-item
                  :href="getRepoLink"
                  target="_blank"
                >
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiGit}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Git details</v-list-item-title>
                    <v-list-item-subtitle>
                      git_repo: <span class="primary--text">{{build.git_repo}}</span><br>
                      git_short_log: <span class="primary--text">{{build.git_short_log}}</span><br>
                      git_ref: <span class="primary--text">{{build.git_ref}}</span><br>
                      git_sha: <span class="primary--text">{{build.git_sha}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :href="build.download_url"
                  target="_blank"
                >
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiFolderZip}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Kernel image</v-list-item-title>
                    <v-list-item-subtitle>
                      {{build.kernel_image}}
                      {{build.kernel_image_name}}
                      <br>kernel version: {{build.kernel_version}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <h4 class="primary--text">State</h4>
              <v-list
                dense
                tile
              >

                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>{{build.state === 'finished' ? icons.mdiFlagCheckered : icons.mdiShoeSneaker}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                    <v-list-item-subtitle>
                      State: <span :class="`${build.result}--text`">{{build.state}}</span><br>
                      TuxBuild Status: <span :class="`${build.result}--text`">{{build.tuxbuild_status}}</span><br>
                      Result: <span :class="`${build.result}--text`">{{build.result}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>{{icons.mdiClockOutline}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Duration</v-list-item-title>
                    <v-list-item-subtitle>{{duration}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col>
            </v-col>
          </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

    </div>

  </div>
</template>

<script>
import moment from 'moment'
import {
  mdiGit,
  mdiCircle,
  mdiRulerSquare,
  mdiHammerWrench,
  mdiAccount,
  mdiFolderZip,
  mdiClockOutline,
  mdiFlagCheckered,
  mdiShoeSneaker
} from '@mdi/js';
export default {
  name: "BuildSingle",
  data() {
    return {
      build: {},
      tab: {},
      icons: {
        mdiGit, mdiCircle,mdiRulerSquare,mdiHammerWrench,mdiAccount,
        mdiFolderZip,mdiClockOutline,mdiFlagCheckered,mdiShoeSneaker
      },
      buildLoaded: false,
      tabItems: [
        'overview',
        // These are disabled until we get CORS working for the s3 endpoints for logs
        // 'definition',
        // 'logs'
      ],
    }
  },
  async created() {
    this.build = await this.$store.dispatch('builds/getBuildDetails', this.$route.params);
    this.buildLoaded = true;
  },
  computed: {

    getRepoLink() {
      return this.$store.getters['projects/getRepoLink'](this.build)
    },
    duration() {
      return moment.utc(this.build.duration*1000).format('HH:mm:ss')
    }
  }
}
</script>

<style lang="scss">
</style>
