<template lang="html">
  <div class="main_pane projects">
    <div class="pane_wrapper">
      <v-skeleton-loader
        type="{table: table-heading, table-thead, table-tbody, table-tfoot}"
        v-show="$store.state.projects.projectInit"
      ></v-skeleton-loader>
      <v-card
        v-show="!$store.state.projects.projectInit"
        elevation="0"
      >
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 mb-4"
        ></v-breadcrumbs>
        <v-row>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import {includes} from 'lodash'
export default {
  name: "ProjectRouter",
  data() {
    return {
      boston: [{
        text: 'boston',
        disabled: false,
        href: 'boston'
      }]
    }
  },
  async created() {
    this.$store.commit('groups/setActiveGroup', this.$route.params.group)
    await this.$store.dispatch('projects/getProjects')
  },
  computed: {
    breadcrumbs() {
      let crumbs = this.$route.path.split("/");
      crumbs.splice(0,1)
      let link = [""]
      return crumbs.map((val) => {
        link.push(val)
        return {
          text: val,
          to: link.join('/'),
          exact: true,
          disabled: includes(['groups', 'projects'], val)
        }
      }).filter(val => {
        return val.text !== ""
      })
    }
  }
}
</script>

<style lang="scss" >

</style>
