<template lang="html">
  <div class="mt-5 mb-0">
    <v-row>
      <v-col cols="6">
        <h3>{{$store.state.projects.selectedProject}}</h3>
        <h5><span class="primary--text ">All {{$route.params.projectName}} plans</span></h5>
      </v-col>
      <v-col cols="6" align="right">
        <p class="caption">TuxSuite plans are away to group your builds and tests into a logical workflow.</p>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="plans"
          :loading="loading"
          @click:row="openPlan"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="mt-6" cols="1" v-show="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col class="mt-6" cols="2" v-show="!moreItems">
        <div class="primary--text text-center">No more items</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProjectSingle",
  data() {
    return {
      bottom: false,
      loading: true,
      moreItems: true,
      nextToken: null,
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Status",
          value: "status"
        }
      ]
    }
  },
  async created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    this.$store.commit('plans/resetPlans')
    await this.getPlans()
    this.loading = false;
  },
  watch: {
    bottom(bottom) {
      if (bottom && !this.loading) {
        this.getPlans()
      }
    }
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    openPlan(item) {
      this.$router.push({
        name: 'PlanOverview',
        params: {
          planId: item.uid,
          projectName: this.$route.params.projectName,
          group: this.$route.params.group
        }
      })
    },
    async getPlans() {
      if (!this.moreItems) {
        return
      }
      this.loading = true;
      try {
        let res = await this.$store.dispatch('plans/getPlans', {
          ...this.$route.params,
          next: this.nextToken
        })

        if (res.next === null) {
          this.moreItems = false;
        }
        this.nextToken = res.next;
      }catch(err) {console.log(err)}
      this.loading = false;
    },
  },
  computed: {
    projectName() {
      return this.$store.state.projects.selectedProject
    },
    plans() {
      return this.$store.state.plans.plans
    }
  }
}
</script>

<style lang="css">
</style>
