<template lang="html">
  <div class="mt-5 mb-0">
    <v-row>
      <v-col cols="6">
        <h3>{{$store.state.projects.selectedProject}}</h3>
        <h5><span class="primary--text ">All {{$route.params.projectName}} builds</span></h5>
      </v-col>
      <!-- <v-col cols="6" align="right">
        <p class="caption">TuxSuite builds are artifacts that are created for download in vzlinux binaries for use iin tes.</p>
      </v-col> -->

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="builds"
          :loading="loading"
          @click:row="openBuild"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.result="{item}">
            <v-avatar
              :color="item.result"
              size="14"
              justify-center
            ></v-avatar>
          </template>
          <template v-slot:item.git_repo="{item}">
            <v-btn
              icon
              :href="getRepoLink(item)"
              target="_blank">
              <v-icon>{{icons.mdiGit}}</v-icon>
            </v-btn>
            <v-btn
              icon
              :href="item.download_url"
              target="_blank">
              <v-icon>{{icons.mdiCloudDownload}}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="mt-6" cols="1" v-show="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col class="mt-6" cols="2" v-show="!moreItems">
        <div class="primary--text text-center">No more items</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiGit,mdiCloudDownload } from '@mdi/js';
export default {
  name: "BuildList",
  data() {
    return {
      bottom: false,
      loading: true,
      moreItems: true,
      nextToken: null,
      icons: {
        mdiGit,
        mdiCloudDownload
      },
      headers: [{
        text: 'Kernel Version',
        value: 'kernel_version'
      },{
        text: 'Toolchain',
        value: 'toolchain'
      },{
        text: 'Architecture',
        value: 'target_arch'
      },{
        text: 'Artifacts',
        value: 'git_repo'
      },{
        text: 'Result',
        value: 'result'
      }]
    }
  },
  async created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    this.$store.commit('builds/resetBuilds')
    await this.getBuilds()
    this.loading = false;
  },
  computed: {
    builds() {
      return this.$store.state.builds.builds
    },
    hasBuilds() {
      return this.$store.state.projects.builds.count > 0
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom && !this.loading) {
        this.getBuilds()
      }
    }
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    getRepoLink(item) {
      return this.$store.getters['projects/getRepoLink'](item)
    },
    openBuild(item) {
      this.$router.push({
        name: 'ProjectBuild',
        params: {
          ...this.$route.params,
          buildId: item.uid,
        }
      })
    },
    async getBuilds() {
      if (!this.moreItems) {
        return
      }
      this.loading = true;
      try {
        let res = await this.$store.dispatch('builds/getBuilds', {
          ...this.$route.params,
          next: this.nextToken
        })

        if (res.next === null) {
          this.moreItems = false;
        }
        this.nextToken = res.next;
      }catch(err) {console.log(err)}
      this.loading = false;
    },
  }
}
</script>

<style lang="css">
</style>
