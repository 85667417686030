<template lang="html">
  <div>
    <v-row class="mb-4">
      <v-col>
        <h3 ><span class="primary--text">{{$route.params.group}}</span> monthly billable usage</h3>
      </v-col>
      <v-col>
        <v-select
          outlined
          dense
          :items="filterBy"
          v-model="filter"
          @change="filterIt"
        ></v-select>
      </v-col>
    </v-row>
    <LineChart
      v-if="filter === 'numbers'"
      :options="options"
      :chart-data="monthlyNumbers"
    />
    <LineChart
      ref="chart"
      v-if="filter === 'duration'"
      :options="options"
      :chart-data="monthlyDuration"
    />

  </div>
</template>

<script>
import LineChart from '@/components/LineChart.vue'
import moment from 'moment'
export default {
  name: "GroupUsage",
  components: {LineChart},
  data() {
    return {
      filter: 'numbers',
      filterBy: [
        'numbers',
        'duration',
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {}
      },
    }
  },
  methods: {
    filterIt(item) {
      this.filter = item;
    }
  },
  computed: {
    monthlyDuration() {
      return {
        labels: this.$store.state.groups.groupUsageInformation.map(val => {
          return moment(val.date).format('MMM YYYY')
        }),
        datasets: [
          {
            label: 'Total duration builds',
            borderColor: this.$vuetify.theme.currentTheme.primary,
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            borderWidth: 3,
            pointBorderColor: 'rgba(255,255,255,.1)',
            backgroundColor: 'transparent',
            data: this.$store.state.groups.groupUsageInformation.map(val => {
              return val.duration.builds
            })
          },{
            label: 'Total duration tests',
            borderColor: this.$vuetify.theme.currentTheme.blue,
            pointBackgroundColor: this.$vuetify.theme.currentTheme.blue,
            borderWidth: 3,
            pointBorderColor: 'rgba(255,255,255,.1)',
            backgroundColor: 'transparent',
            data: this.$store.state.groups.groupUsageInformation.map(val => {
              return val.duration.tests
            })
          }
        ]
      }
    },
    monthlyNumbers() {
      return {
        labels: this.$store.state.groups.groupUsageInformation.map(val => {
          return moment(val.date).format('MMM YYYY')
        }),
        datasets: [
          {
            label: 'Total monthly builds',
            borderColor: this.$vuetify.theme.currentTheme.primary,
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            borderWidth: 3,
            pointBorderColor: 'rgba(255,255,255,.1)',
            backgroundColor: 'transparent',
            data: this.$store.state.groups.groupUsageInformation.map(val => {
              return val.count.builds
            })
          },{
            label: 'Total monthly tests',
            borderColor: this.$vuetify.theme.currentTheme.blue,
            pointBackgroundColor: this.$vuetify.theme.currentTheme.blue,
            borderWidth: 3,
            pointBorderColor: 'rgba(255,255,255,.1)',
            backgroundColor: 'transparent',
            data: this.$store.state.groups.groupUsageInformation.map(val => {
              return val.count.tests
            })
          },{
            label: 'Total monthly plans',
            borderColor: this.$vuetify.theme.currentTheme.red,
            pointBackgroundColor: this.$vuetify.theme.currentTheme.red,
            borderWidth: 3,
            pointBorderColor: 'rgba(255,255,255,.1)',
            backgroundColor: 'transparent',
            data: this.$store.state.groups.groupUsageInformation.map(val => {
              return val.count.plans
            })
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
