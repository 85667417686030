var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("All tests for "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$route.params.projectName))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tests,"hide-default-footer":"","disable-pagination":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort},on:{"click:row":_vm.goToTest,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.finished_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finished_time_m)+" ")]}},{key:"item.submitted_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.provisioning_time_m)+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"overline mr-1"},[_vm._v(_vm._s(item.result === 'unknown' ? item.state : item.result))]),_c('v-avatar',{attrs:{"color":item.result === 'unknown' ? item.state : item.result,"size":"14","justify-center":""}})]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',[(Object.keys(item.results).length)?_vm._l((item.results),function(test,idx){return _c('v-chip',{key:(idx + "-" + test),attrs:{"color":test}},[_vm._v(_vm._s(idx))])}):_vm._l((item.tests),function(name){return _c('v-chip',{key:("" + name),attrs:{"color":"unknown"}},[_vm._v(_vm._s(name))])})],2)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mt-6",attrs:{"cols":"1"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.moreItems),expression:"!moreItems"}],staticClass:"mt-6",attrs:{"cols":"2"}},[_c('div',{staticClass:"primary--text text-center"},[_vm._v("No more items")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }