<template lang="html">
  <div class="main_loading">
    <div class="loading_wrap">
      <img src="@/assets/penguin.svg" alt="Penguin flapping">
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingMain"
}
</script>

<style lang="scss">
.main_loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  background-opacity: 0.4;
  position:absolute;
  .loading_wrap {
    height: 200px;
    width: 200px;
    img {
      width: 100%;
      height: 100%;
      animation: bounce-logo 1.6s infinite;
    }
  }
}
@keyframes bounce-logo {
  0% {
    opacity: 0.1;
    animation-timing-function: ease-in;
  }
  35% {
    animation-timing-function: ease-out;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
