<template lang="html">
  <div class="">
    <v-row
      v-show="hasTests"
    >
      <v-col>No builds are detected for this plan.</v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="$store.state.itemsPerPage"
      v-show="!hasTests"
      @click:row="goToTest"
      hide-default-footer
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:item.provisioning_time="{item}">
        {{getFinishedTime(item.provisioning_time)}}
      </template>
      <template v-slot:item.finished_time="{item}">
        {{getFinishedTime(item.finished_time)}}
      </template>
      <template v-slot:item.result="{item}">
        <v-avatar
          :color="item.result"
          size="14"
          justify-center
        ></v-avatar>
      </template>
      <template v-slot:item.tests="{item}">
        <v-chip-group
        >
          <template v-if="Object.keys(item.results).length">
            <v-chip
              v-for="(test, idx) in item.results"
              :key="`${idx}-${test}`"
              :color="test"
            >{{idx}}</v-chip>
          </template>
          <template v-else>
            <v-chip
              v-for="name in item.tests"
              :key="`${name}`"
              color="unknown"
            >{{name}}</v-chip>
          </template>
        </v-chip-group>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';

const tzOffset = new Date().getTimezoneOffset();

export default {
  name: "TestPanel",
  props: ['tests'],
  data() {
    return {
      headers: [{
        text: 'Device',
        value: 'device'
      },{
        text: 'Tests',
        value: 'tests'
      },{
        text: 'Started at',
        value: 'provisioning_time'
      },{
        text: 'Finished',
        value: 'finished_time'
      },{
        text: 'Result',
        value: 'result',
        align: 'center'
      }],
      sortBy: 'finished_time',
      sortDesc: true,
    }
  },
  methods: {
    getFinishedTime(item) {
      return moment(item).isValid() ? moment(item).subtract(tzOffset, 'minutes').fromNow() : null
    },
    goToTest(item) {
      this.$router.push(`/groups/${this.$route.params.group}/projects/${this.$route.params.projectName}/tests/${item.uid}`)
    }
  },
  computed: {
    hasTests() {
      return this.$store.state.projects.selectedPlanData.tests.results > 0
    },
    items() {
      return this.$store.state.projects.selectedPlanData.tests.results
    }
  }
}
</script>

<style lang="scss">
</style>
