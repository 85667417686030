import {$tuxApi} from '@/plugins/axios.js'


const state = () => ({
  build: {},
  next: null,
  prev: null,
  count: 0,
  currentPage: 1,
  builds: []
})

const getters = {
  allBuilds(state) {
    return state.builds
  },
  currentPage() {
    return state.currentPage
  }
}

const mutations = {
  resetBuilds(state) {
    return state.builds = []
  },
  updateBuilds(state, builds) {
    return state.builds = state.builds.concat(builds)
  },
  updateNext(state, nextToken) {
    return state.next = nextToken
  },
  updateCount(state, count) {
    return state.count = count
  }
}

const actions = {

  async getBuildDetails(context, params) {
    let res = await $tuxApi(`/v1/groups/${params.group}/projects/${params.projectName}/builds/${params.buildId}`)
    return res.data
  },
  async getBuilds(context, payload) {
    let params;
    if (payload.next) {
      params = {start: payload.next}
    }
    let res = await $tuxApi(`/v1/groups/${payload.group}/projects/${payload.projectName}/builds`, {params: params})
    context.commit('updateBuilds', res.data.results)
    context.commit('updateNext', res.data.next)
    context.commit('updateCount', res.data.count)
    return res.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
