<template lang="html">
  <div>
    <v-row>
      <v-col><h2>My Tokens</h2></v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-card
          :loading="loading"
        >
          <div class="token-view">
            <v-data-table
              :headers="headers"
              :items="tokens"
              class="elevation-1"
            >
              <template v-slot:item.LastUsed="{ item }">
                {{ convertTime(item.LastUsed) }}
              </template>
              <template v-slot:item.Actions="{ item }">
                <v-icon
                  @click="deleteToken(item)"
                  color="red">{{ icons.mdiDelete }}
                </v-icon>
              </template>

            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title><h3>Create a Token</h3></v-card-title>
          <v-card-text>Create a TuxSuite token in order to utilize the CLI or integrate in with your custom build systems.

            <v-row>
              <v-col class="mt-4">
                <v-text-field
                  label="Token name"
                  outlined
                  v-model="tokenCreate.name"
                ></v-text-field>
                <v-btn
                elevation="0"
                @click="createToken()"
                color="primary"
                :disabled="tokenCreate.waiting"
                >Create</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="tokenCreate.display"
      top
      timeout="-1"
    >
      <div class="sideways-token"><span>Token</span></div><span class="token-snackbar">{{tokenCreate.value}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          elevation="0"
          color="primary"
          v-bind="attrs"
          @click="tokenCreate.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {utc} from 'moment'
import {
  mdiDelete,
} from '@mdi/js'
export default {
  name: "TokenMain",
  async created() {
    await this.$store.dispatch('tokens/listMyTokens');
    this.loading = false;
  },
  data() {
    return {
      loading: 'primary',
      icons: {mdiDelete},
      tokenCreate: {
        name: '',
        display: false,
        value: ''
      },
      headers: [
        {
          text: 'Token Name',
          align: 'start',
          value: 'FriendlyName',
        },
        { text: 'Last Used', value: 'LastUsed' },
        { value: 'Actions'}
      ]
    }
  },
  computed: {
    tokens() {
      return this.$store.getters['tokens/allTokens']
    }
  },
  methods: {
    convertTime(time) {
      return utc(time).fromNow()
    },
    async deleteToken(token) {
      await this.$store.dispatch('tokens/deleteToken', {ID: token.SK.split('#')[1]})
    },
    async createToken() {
      this.tokenCreate.waiting = true;
      let res = await this.$store.dispatch('tokens/createToken', {
        Policy: {
            tuxtest: {
              permissions: ["*"]
            },
            tuxmake: {
              permissions: ["*"]
            },
            tuxbuild: {
              permissions: ["*"]
            },
            tuxpub: {
              permissions: ["*"]
            }
        },
        PolicyVersion: "11-04-2020",
        FriendlyName: this.tokenCreate.name
      })
      this.tokenCreate.waiting = false;
      if (res.status === 200) {
        this.tokenCreate.value = res.data.Token;
        this.tokenCreate.display = true;
        this.tokenCreate.name = '';
      }
    }
  }
}
</script>

<style lang="scss">
.token-snackbar {
  color: black;
  padding: 4px 8px;
  margin-left:28px;
  border-radius: 2px;
  font-family: Courier;
  letter-spacing: 1px;
}
.v-snack__wrapper.theme--dark {
  background-color: white !important;
  border-radius: 6px !important;
}
.sideways-token {
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  width: 30px;
  background-color: $orange;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: inset -4px 0 4px rgba(0,0,0,.04);
  span {
    user-select: none;
    position: absolute;
    color: white;
    transform: rotateZ(270deg) translateX(-14px) translateY(-4px);

  }
}
</style>
