<template lang="html">
  <div class="">
    <v-row>
      <v-col>
        <h3>All tests for <span class="primary--text">{{$route.params.projectName}}</span></h3>
        <v-data-table
          :headers="headers"
          :items="tests"
          @click:row="goToTest"
          hide-default-footer
          disable-pagination
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :custom-sort="customSort"
        >
          <template v-slot:item.finished_time="{item}">
            {{item.finished_time_m}}
          </template>
          <template v-slot:item.submitted_time="{item}">
            {{item.provisioning_time_m}}
          </template>
          <template v-slot:item.result="{item}">
            <span class="overline mr-1">{{item.result === 'unknown' ? item.state : item.result}}</span>
            <v-avatar
              :color="item.result === 'unknown' ? item.state : item.result"
              size="14"
              justify-center
            ></v-avatar>
          </template>
          <template v-slot:item.tests="{item}">
            <v-chip-group
            >
              <template v-if="Object.keys(item.results).length">
                <v-chip
                  v-for="(test, idx) in item.results"
                  :key="`${idx}-${test}`"
                  :color="test"
                >{{idx}}</v-chip>
              </template>
              <template v-else>
                <v-chip
                  v-for="name in item.tests"
                  :key="`${name}`"
                  color="unknown"
                >{{name}}</v-chip>
              </template>


            </v-chip-group>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="mt-6" cols="1" v-show="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col class="mt-6" cols="2" v-show="!moreItems">
        <div class="primary--text text-center">No more items</div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "TestList",
  data() {
    return {
      bottom: false,
      loading: true,
      nextToken: null,
      moreItems: true,
      headers: [{
        text: 'UID',
        value: 'uid'
      },{
        text: 'Device',
        value: 'device'
      },{
        text: 'Tests',
        value: 'tests'
      },{
        text: 'Submitted',
        value: 'submitted_time'
      },{
        text: 'Finished',
        value: 'finished_time'
      },{
        text: 'Result',
        value: 'result',
        align: 'center'
      }],
      options: {},
      sortBy: 'finished_time',
      sortDesc: true,
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    this.$store.commit('tests/resetTests')
    this.getTests()
  },
  watch: {
    bottom(bottom) {
      if (bottom && !this.loading) {
        this.getTests()
      }
    }
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === "date") {
          if (!isDesc) {
            return this.compare(a.date, b.date);
          } else {
            return this.compare(b.date, a.date);
          }
        }
      });
      return items;
    },
    compare:function(a,b) {
        return (
            isFinite(a=this.convert(a).valueOf()) &&
            isFinite(b=this.convert(b).valueOf()) ?
            (a>b)-(a<b) :
            NaN
        );
    },
    goToTest(item) {
      this.$router.push(
        `/groups/${this.$route.params.group}/projects/${this.$route.params.projectName}/tests/${item.uid}`
      )
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    async getTests() {
      if (!this.moreItems) {
        return
      }
      this.loading = true;
      try {
        let res = await this.$store.dispatch('tests/getTests', {
          ...this.$route.params,
          next: this.nextToken
        })
        if (res.next === null) {
          this.moreItems = false;
        }
        this.nextToken = res.next;
      }catch(err) {console.log(err)}
      this.loading = false;
    },
  },
  computed: {
    tests() {
      return this.$store.getters['tests/allTests']
    },
    numPages() {
      return this.$store.getters['tests/numPages']
    }
  }
}
</script>

<style lang="css">
</style>
