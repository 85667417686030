<template lang="html">
  <div>
    <v-list-item
      :to="{name: 'ProjectTest', params: { testId: testItem.uid}}"
    >
      <v-list-item-content>
        <v-list-item-title>{{testItem.device}}</v-list-item-title>
        <v-list-item-subtitle>{{testItem.finished_time_m}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <span class="mr-1">{{testItem.result === 'unknown' ? testItem.state : testItem.result}}</span>
        <v-icon
          :color="testItem.result === 'unknown' ? testItem.state : testItem.result"
        >
          {{icons.mdiCircle}}
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import moment from 'moment'
import { mdiCircle } from '@mdi/js';
export default {
  name: "TestListItem",
  props: ["testItem"],
  data() {
    return {
      icons: {mdiCircle}
    }
  },
  methods: {
    getFinishedTime(item) {
      return moment(item).fromNow()
    }
  }
}
</script>

<style lang="scss">
</style>
