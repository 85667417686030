<template lang="html">
  <v-navigation-drawer permanent absolute dark color="gray">
      <v-list
      dark
      >
        <v-list-item
          v-for="item in menuItems"
          :to="item.path"
          :key="item.title"
          v-show="item.ga"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title><span class="menu-text">{{ item.title }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import {
  mdiLock,
  mdiHome,
  mdiSitemap
} from '@mdi/js'
export default {
  name: "NavLeft",
  data() {
    return {}
  },
  computed: {
    menuItems() {
      return [{
        icon: mdiHome,
        title: 'Home',
        path: '/home',
        ga: true
      },
      {
        icon: mdiSitemap,
        title: 'Projects',
        path: `/groups/${this.$store.getters['groups/selectedGroup']}`,
        ga: this.$store.state.users.user.BetaModes.UIEnabled
      },
      {
        icon: mdiLock,
        title: 'Tokens',
        path: '/tokens',
        ga: true
      }]
    },
    selectedGroup() {
      return this.$store.getters['groups/selectedGroup']
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.menu-text {
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 1.2rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,.36);
}
</style>
