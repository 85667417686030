<template lang="html">
  <div class="main_pane profile">
    <div class="pane_wrapper">
      <v-row>
        <v-col><h2>Your Profile</h2></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            elevation="1"
            class="pa-4 pb-12"
          >
            <v-row>
              <v-col class="col-1">
                <v-img
                  :src="$auth.user.picture"
                ></v-img>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Name"
                      :value="$auth.user.name"
                      disabled
                    ></v-text-field>
                    <!-- <v-text-field
                      label="Subscription Level"
                      :value="$store.state.users.user.SubscriptionLevel"
                      disabled
                    ></v-text-field> -->
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Date Joined"
                      :value="dateJoined"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      elevation="0"
                    >
                      <v-switch
                      :loading="uiSwitchLoading"
                      :input-value="$store.state.users.user.BetaModes.UIEnabled"
                      @click="enableBetaMode"
                      inset
                      label="Beta User Interface Features"
                      ></v-switch>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-dialog
                      v-model="dialog"
                      width="500"
                    >
                      <template v-slot:activator="{  }">
                        <v-btn
                          @click="dialog = true"
                          color="primary"
                          elevation="0"
                        >Change Password</v-btn>
                      </template>

                      <v-card color="white" class="pb-3">
                        <v-card-title class="headline">
                          Change Your TuxSuite Password
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          After resetting, check your email for instructions on how to change your password.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="changePassword"
                            :disabled="resetting"
                          >
                            Reset Password
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>
import {utc} from 'moment';
export default {
  name: "Profile",
  data() {
    return {
      dialog: false,
      resetting: false,
      uiSwitchLoading: false
    }
  },
  methods: {
    async changePassword() {
      this.resetting = true;
      let res = await this.$store.dispatch('users/changePassword');
      if (res.status !== 200) {
        alert('Reset failed. Please sign in again or check with the administrator.')
      } else {
        this.dialog = false;
      }
      this.resetting = false;
    },
    async enableBetaMode() {
      this.uiSwitchLoading = true;
      await this.$store.dispatch('users/updateUserDetails', {
          "BetaModes": {
            "UIEnabled": !this.$store.state.users.user.BetaModes.UIEnabled
          }
      })
      this.uiSwitchLoading = false;
    }
  },
  computed: {
    dateJoined() {
      return utc(this.$store.state.users.user.DateJoined).format('LL')
    },
    uiBetaModeEnabled() {
      return this.$store.state.users.user.BetaModes.UIEnabled
    }
  }
}
</script>

<style lang="scss">
</style>
