<template lang="html">
  <div class="">
    <v-card elevation="0" class="mt-4">
      <v-row justify="space-between">
        <v-col cols="3">
          <v-card
            elevation="0"
            class="text-center"
          >
            <h3>Total Plans</h3>
            <v-card
              class=" pa-4"
              elevation="0"
              color="grey"
            ><h4 class=" white--text">{{totalPlans}}</h4></v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            elevation="0"
            class="text-center"
          >
            <h3>Total Tests</h3>
            <v-card
              class=" pa-4"
              elevation="0"
              color="blue"
            ><h4 class=" white--text">{{totalTests}}</h4></v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            elevation="0"
            class=" text-center"
          >
            <h3>Total Builds</h3>
            <v-card
              class=" pa-4"
              elevation="0"
              color="primary"
            ><h4 class=" white--text">{{totalBuilds}}</h4></v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="filterBy"
            v-model="filterSelect"
            outlined
            dense
            color="primary"
            class="ml-4 mt-7"
          ></v-select>
        </v-col>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="projects"
            @click:row="openProject"
          >
            <template v-slot:header.builds>
                Builds
            </template>
            <template v-slot:header.tests>
                Tests
            </template>
            <template v-slot:row="{ item }">
              <v-chip
                dark
              >
                {{ item.name }}
              </v-chip>
            </template>
            <template v-slot:item.builds="{ item }">
                {{ getAmount(item.builds, filterSelect) }}
            </template>
            <template v-slot:item.tests="{ item }">
                {{ getAmount(item.tests, filterSelect) }}
            </template>
            <template v-slot:item.plans="{ item }">
                {{ getAmount(item.plans, filterSelect) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GroupOverview",
  data() {
    return {
      filterSelect: 'monthly',
      filterBy: [
        'daily',
        'monthly',
        'overall'
      ],
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Plans",
          value: "plans",
          align: "center"
        },
        {
          text: "Builds",
          value: "builds",
          align: "center"
        },
        {
          text: "Tests",
          value: "tests",
          align: "center"
        }
      ]
    }
  },
  methods: {
    openProject(item) {
      this.$router.push({
        name: 'ProjectOverview',
        params: {
          projectName: item.name
        }
      })
    },
    getAmount(items, key) {
      if (items) {
        return items[key]
      }
      return 0
    }
  },
  computed: {
    projects() {
      return this.$store.state.projects.projects
    },
    details() {
      return this.$store.state.groups.groupDetails
    },
    totalBuilds() {
      return this.$store.state.groups.groupDetails['builds'][this.filterSelect]
    },
    totalTests() {
      return this.$store.state.groups.groupDetails['tests'][this.filterSelect]
    },
    totalPlans() {
      return this.$store.state.groups.groupDetails['plans'][this.filterSelect]
    },
  }
}
</script>

<style lang="scss">
</style>
