import {$tuxAuth} from '@/plugins/axios.js'

const state = () => ({
  user: {},
  signinStatus: null
})

const getters = {

}

const actions = {
  async updateUser(context) {
    let userInfo = await $tuxAuth.get('/v1/users/me')
    context.commit("setUser", userInfo.data);
  },
  async updateUserDetails(context, payload) {
    let userInfo;
    try{
      userInfo = await $tuxAuth.put('/v1/users/me', payload)
      context.commit("setUser", userInfo.data)
      context.commit("updateComponentKey", context.rootState.componentKey + 1, {root: true})
      return userInfo
    }catch(err) {
      return err.response
    }

  },
  async changePassword(context) {
    try {
      let res = await $tuxAuth({
        method: 'POST',
        url: 'dbconnections/change_password',
        baseURL: 'https://tuxsuite.us.auth0.com',
        data: {
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          email: context.state.user.Email,
          connection: process.env.VUE_APP_AUTH0_DB_CONNECTION
        }
      })
      return res
    }catch(err) {
      return err.response
    }
  }
}

const mutations = {
  setUser(state, payload) {
    state.user = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
