<template lang="html">
  <div class="pa-6">
    <v-row v-show="!hasBuilds">
      <v-col>No builds are detected for this plan.</v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="$store.state.itemsPerPage"
      @click:row="goToBuild"
      v-show="hasBuilds"
      hide-default-footer
    >
    <template v-slot:item.result="{item}">
      <v-avatar
        :color="item.result"
        size="14"
        justify-center
      ></v-avatar>
    </template>
    <template v-slot:item.git_repo="{item}">
      <v-btn
        icon
        :href="getRepoLink(item)"
        target="_blank">
        <v-icon>{{icons.mdiGit}}</v-icon>
      </v-btn>
      <v-btn
        icon
        :href="item.download_url"
        target="_blank">
        <v-icon>{{icons.mdiCloudDownload}}</v-icon>
      </v-btn>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mdiGit,mdiCloudDownload } from '@mdi/js';
export default {
  name: "BuildPanel",
  data() {
    return {
      icons: {
        mdiGit,
        mdiCloudDownload
      },
      headers: [{
        text: 'Kernel Version',
        value: 'kernel_version'
      },{
        text: 'Toolchain',
        value: 'toolchain'
      },{
        text: 'Architecture',
        value: 'target_arch'
      },{
        text: 'Artifacts',
        value: 'git_repo'
      },{
        text: 'Result',
        value: 'result'
      }]
    }
  },
  methods: {
    goToBuild(item) {
      this.$router.push(`/groups/${this.$route.params.group}/projects/${this.$route.params.projectName}/builds/${item.uid}/`)
    },
    getRepoLink(item) {
      return this.$store.getters['projects/getRepoLink'](item)
    }
  },
  computed: {
    itemsPerPage() {
      return 50
    },
    hasBuilds() {
      return this.$store.state.projects['selectedPlanData'].builds.count > 0
    },
    items() {
      return this.$store.state.projects['selectedPlanData'].builds.results
    }
  }
}
</script>

<style lang="scss">
</style>
