import axios from 'axios';

// Set config defaults when creating the instance
const $tuxAuth = axios.create({
  baseURL: process.env.VUE_APP_TUXAUTH_ENDPOINT
});
const $tuxApi = axios.create({
  baseURL: process.env.VUE_APP_TUXAPI_ENDPOINT
});

export {$tuxAuth, $tuxApi};
